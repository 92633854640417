import { Route, Switch } from "react-router-dom"
import {
    Congratulations,
    NDA,
    Billing,
    ApplicationForm,
    Interview,
    BackgroundCheck,
    ManualCheck,
    NotFound,
} from "pages"
import { Fragment } from "react"
import { Helmet } from "react-helmet"
import { useEffect } from "react"
import { useUser } from "hooks/useUser"
import { setItem } from "services/localStorage.service"
import { getUserData } from "services/user.service"

function App() {
    const { uniqueId } = useUser()

    useEffect(() => {
        const fetchUser = async lang => {
            setItem("wglang", lang)
            await getUserData(uniqueId)
        }

        const onLangChange = async lang => {
            await fetchUser(lang)
        }

        if (window.Weglot && !window.Weglot.initialized) {
            window.Weglot.initialize({
                api_key: "wg_bc9842a957c13d432b7f0d1bd29db8f73",
                translate_iframes: [".launchlist-iframe"],
            })

            window.Weglot.on("languageChanged", onLangChange)
        }

        return () => {
            window.Weglot.off("languageChanged", onLangChange)
        }
    }, [window.Weglot])

    return (
        <Fragment>
            <Helmet>
                <script>
                    {`Weglot.initialize({
                            api_key: 'wg_bc9842a957c13d432b7f0d1bd29db8f73',
                            translate_iframes: [".launchlist-iframe"]
                        });`}
                </script>
            </Helmet>
            <Switch>
                <Route exact path="/congratulations" component={Congratulations} />
                <Route exact path="/:id" component={ApplicationForm} />
                <Route path="/interview/:id" component={Interview} />
                <Route path="/background-check/:id" component={BackgroundCheck} />
                <Route path="/manual-check/:id" component={ManualCheck} />
                <Route path="/billing/:id" component={Billing} />
                <Route path="/nda/:id" component={NDA} />
                <Route component={NotFound} />
            </Switch>
        </Fragment>
    )
}

export default App
