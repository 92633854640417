import React, { createContext, useState, useEffect } from "react"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import { getUserData } from "services/user.service"
import { useRouter } from "hooks/useRouter"

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(null)

    const { pathname, push } = useRouter()

    useEffect(() => {
        if (pathname === "/congratulations") {
            push("/congratulations")
        } else {
            ;(async () => {
                setLoading(true)
                try {
                    const parse = pathname.split("/")
                    const unique_id = parse[parse.length - 1]
                    const resp = await getUserData(unique_id)

                    setUser(resp)
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                    console.error("Error fetching user:", error)
                    push("/")
                }
            })()
        }
    }, [pathname, push])

    if (loading)
        return (
            <Backdrop open={loading} sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )

    return (
        <UserContext.Provider
            value={{
                uniqueId: user?.applicant[0]?.unique_id,
                user,
                setUser,
                loading,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}
