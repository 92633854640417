import React, { useState } from "react"
import styled from "styled-components"
import { IconBilling, IconCalendar, IconClipboard, IconList, IconTask, IconUser } from "./Icons"
import { Logo } from "./Logo"
import { NDA_STATUS, STATUS, STEPS } from "utils/constants"
import { useUser } from "hooks/useUser"
import { SidebarItem } from "./SidebarItem"
import { colors } from "utils/styles"
import { EastOutlined } from "@mui/icons-material"
import { GetLaunchModal } from "./GetLaunchModal"


const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 350px;
    position: relative;
    min-height: 100vh;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(157, 157, 157, 0.1), 2px 0 2px 0 rgba(157, 157, 157, 0.1);
    z-index: 10;
`

const SidebarHeader = styled.div`
    margin: 20px 0 10px;
    display: flex;
    space-between: 20px;
    padding: 0 20px;
`

const ReferralDiv = styled.div`
    padding: 10px;
    text-align: center;
    background-color: ${colors.logoBlue};
    border-radius: 10px;
    width: 250px;
    margin: auto;
`

const Container = styled.div`
    padding: 20px;
    margin-top: 20px;
`

const ReferralButton = styled.button`
    background-color: whitesmoke;
    border-radius: 50px;
    cursor: pointer;
    padding: 8px;
    display: flex;
    margin: auto;
    align-items: center;
    gap: 10px;
    width: 220px;
    justify-content: center;
    font-weight: 600;
    border: none;
    margin-bottom: 15px;
    margin-top: 0;
`

const ReferralTitle = styled.p`
    color: var(--White, #fff);
    text-align: center;
    /* Text lg/Bold */
    font-size: 20px;
    font-weight: 700;
`

function Sidebar() {
    const { user } = useUser()
    const [showModal, setShowModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [src, setSrc] = useState(null)

    const handleSubmit = e => {
        let forms = document.querySelectorAll(".launchlist-form-popup")
        // submit form
        forms.forEach(form => {
            form.addEventListener("submit", function (e) {
                e.preventDefault()

                // Get the action url of the form
                let action = form.getAttribute("action")
                setSaving(true)

                // Get the form data
                let formData = new FormData(form)

                // Submit the form
                fetch(action, {
                    method: "POST",
                    body: formData,
                    headers: {
                        Accept: "application/json",
                    },
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.ok) {
                            // replace iframe src
                            setShowModal(true)
                            setSrc(data.embeddedLink)
                            setSaving(false)
                            let iframe = document.querySelector(".launchlist-iframe")

                            iframe.src = data.embeddedLink
                            // document.querySelector(".launchlist-modal").classList.add("launchlist-show-modal")
                            // show modal
                        } else {
                            // You can catch error here
                            // alert(data.error);
                            setSaving(false)
                        }
                    })
                    .catch(error => {
                        // You can catch error here
                    })
            })
        })
    }

    return (
        <SidebarContainer>
            <SidebarHeader>
                <Logo />
            </SidebarHeader>

            <SidebarItem
                to={`/${user?.applicant[0]?.unique_id}`}
                name={STEPS.FORM}
                icon={color => <IconClipboard color={color} bgColor={color} />}
                status={
                    user?.applicant[0]?.application_form_status === 0 || !user?.applicant[0]?.application_form_status
                        ? STATUS.WAITING
                        : STATUS.COMPLETED
                }
            />
            <SidebarItem
                to={"/interview/" + user?.applicant[0]?.unique_id}
                name={STEPS.INTERVIEW}
                icon={color => <IconUser color={color} bgColor={color} />}
                status={
                    user?.applicant[0]?.video_interview_status === 0 || !user?.applicant[0]?.video_interview_status
                        ? STATUS.WAITING
                        : STATUS.COMPLETED
                }
            />
            <SidebarItem
                to={"/background-check/" + user?.applicant[0]?.unique_id}
                name={STEPS.BACKGROUND_CHECK}
                icon={color => <IconTask color={color} bgColor={color} />}
                // status="Inactive"
                status={user?.background_check[0]?.status ? user?.background_check[0]?.status : STATUS.WAITING}
            />
            <SidebarItem
                to={"/manual-check/" + user?.applicant[0]?.unique_id}
                name={STEPS.MANUAL_CHECK}
                icon={color => <IconCalendar color={color} bgColor={color} />}
                status={user?.applicant[0]?.manual_check_status}
            />
            <SidebarItem
                to={"/nda/" + user?.applicant[0]?.unique_id}
                name={STEPS.NDA}
                icon={color => <IconList color={color} bgColor={color} />}
                // status="Inactive"
                status={user?.nda[0]?.contract_state === NDA_STATUS.SIGNED ? STATUS.COMPLETED : STATUS.WAITING}
            />
            <SidebarItem
                to={"/billing/" + user?.applicant[0]?.unique_id}
                name={STEPS.BILLING}
                icon={color => <IconBilling color={color} bgColor={color} />}
                status={
                    user?.applicant[0]?.billing_information_status === 0 ||
                    !user?.applicant[0]?.billing_information_status
                        ? STATUS.WAITING
                        : STATUS.COMPLETED
                }
            />

            {/* <Box position="absolute" textAlign="center" fontSize="small" bottom={20}>
                <Typography alignItems="center" fontWeight={300}>
                    Applicant ID: <span style={{ fontWeight: "bold" }}>{uniqueId}</span>
                </Typography>
            </Box> */}
            <Container>
                <ReferralDiv>
                    <ReferralTitle>Refer to Win Rewards</ReferralTitle>
                    <form
                        target="_blank"
                        className="launchlist-form-popup"
                        action="https://getlaunchlist.com/s/sdcLwg"
                        method="POST"
                    >
                        <input
                            name="email"
                            value={user?.applicant[0].email_id}
                            type="email"
                            readOnly
                            hidden
                            placeholder="Enter your email"
                        />
                        <ReferralButton type="submit" onClick={handleSubmit}>
                            {saving ? "Please wait..." : "Refer Your Colleague"}
                            <EastOutlined fontSize="small" />
                        </ReferralButton>
                    </form>
                </ReferralDiv>
            </Container>
            <GetLaunchModal open={showModal} setOpen={setShowModal}>
                <iframe
                    src={src}
                    width="100%"
                    style={{ height: "100vh" }}
                    frameborder="0"
                    className="launchlist-iframe"
                    title="getlaunch"
                />
            </GetLaunchModal>
        </SidebarContainer>
    )
}

export default Sidebar
