import { Box, Button, Grid, List, ListItem, Typography } from "@mui/material"
import { useRouter } from "hooks/useRouter"
import { useUser } from "hooks/useUser"
import React, { useEffect, useState } from "react"
import { getUserData, getSpecialties, translateYoupal } from "services/user.service"
import { toast } from "react-toastify"
import MainLayout from "components/layout/MainLayout"
import { Modal } from "components/Modal"
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import axiosInstance from "utils/axiosInstance"
import MuiPhoneNumber from 'mui-phone-number';


const questionbubbleStyle = {
    marginLeft: 25,
    background: "rgb(245, 245, 245)",
    paddingTop: 16,
    paddingLeft: 25,
    paddingBottom: 16,
    paddingRight: 25,
    fontWeight: 410,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    borderColor: '#e5e5e5',
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderRadius: "10px"
};

const answerbubbleStyle = {
    background: "rgba(74, 144, 226, 1)",
    paddingTop: 20,
    paddingLeft: 30,
    paddingBottom: 20,
    paddingRight: 30,
    color: "white",
    borderColor: '#e5e5e5',
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderRadius: "10px",
    fontWeight: 410,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "1rem",
};

const startanswerbuttonStyle = {
    background: "rgba(74, 144, 226, 1)",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 40,
    color: "white",
    borderColor: "rgba(255, 255, 255, 0.56)",
    borderRadius: 10,
    fontWeight: 410,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "1rem",
    cursor: "pointer"
};

const answerbuttonStyle = {
    background: "rgba(74, 144, 226, 1)",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 40,
    paddingRight: 40,
    color: "white",
    borderColor: "rgba(255, 255, 255, 0.56)",
    borderRadius: 10,
    fontWeight: 410,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "1rem",
    cursor: "pointer"
};

let specialties = [];
getSpecialties()
    .then(data => {
        if (data) {
            data?.data.forEach(specialty => {
                specialties.push(specialty.specialty);
            });
        }
    });
const availabilities = ["Less than 20 hours/week", "More than 20 hours/week"]
const genders = ["Male", "Female", "Other"]
const languages = ["Afrikaans", "Amharic", "Arabic", "Asturian", "Azerbaijani", "Belarusian", "Bulgarian", "Bengali", "Tibetan", "Bosnian", "Catalan", "Czech", "Welsh", "Danish", "German", "Dzongkha", "Greek", "English", "Simple English", "Esperanto", "Spanish", "Estonian", "Basque", "Persian, Farsi", "Finnish", "Filipino", "Faeroese", "French", "Frisian, Western", "Irish", "Scots Gaelic", "Galician", "Swiss German", "Gujarati", "Hebrew", "Hindi", "Croatian", "Haitian Creole", "Hungarian", "Armenian", "Indonesian", "Icelandic", "Italian", "Japanese", "Javanese", "Georgian", "Kazakh", "Khmer", "Kannada", "Korean", "Kurdish", "Kyrgyz", "Lao", "Lithuanian", "Latvian", "Malagasy", "Macedonian", "Malayalam", "Mongolian", "Marathi", "Bahasa Malaysia", "Burmese", "Nepali", "Dutch", "Norwegian Bokmål", "Norwegian Nynorsk", "Occitan", "Punjabi", "Polish", "Portuguese, Portugal", "Portuguese, Brazil", "Romanian", "Russian", "Scots", "Northern Sami", "Sinhala", "Slovak", "Slovenian", "Albanian", "Serbian", "Swedish", "Swahili", "Tamil", "Sri Lanka", "Telugu", "Thai", "Turkish", "Tuvan", "Uyghur", "Ukrainian", "Urdu", "Vietnamese", "Lolspeak", "Chinese, Simplified", "Chinese, Traditional"]

export const ApplicationForm = () => {
    //process variables
    const [openEduDialog, setopenEduDialog] = useState(false)
    const [openExpDialog, setopenExpDialog] = useState(false)
    const [openDocDialog, setopenDocDialog] = useState(false)
    const [openRefDialog, setopenRefDialog] = useState(false)
    const [openLangDialog, setopenLangDialog] = useState(false)
    const [step, setStep] = useState(1)
    const [step1Disable, setstep1Disable] = useState(false)
    const [step2Disable, setstep2Disable] = useState(false)
    const [step3Disable, setstep3Disable] = useState(false)
    const [step4Disable, setstep4Disable] = useState(false)
    const [step5Disable, setstep5Disable] = useState(false)
    const [step6Disable, setstep6Disable] = useState(false)
    const [step7Disable, setstep7Disable] = useState(false)
    const [step8Disable, setstep8Disable] = useState(false)
    const [step9Disable, setstep9Disable] = useState(false)
    const [step10Disable, setstep10Disable] = useState(false)
    const [step11Disable, setstep11Disable] = useState(false)
    const [step12Disable, setstep12Disable] = useState(false)
    const [step13Disable, setstep13Disable] = useState(false)
    //data variables
    const { user, uniqueId, setUser } = useUser()
    const [showModal, setShowModal] = useState(false)
    const [educationFrom, seteducationFrom] = useState(Dayjs('2024-01-01'))
    const [educationTo, seteducationTo] = useState(Dayjs('2024-01-01'))
    const [experienceFrom, setexperienceFrom] = useState(Dayjs('2024-01-01'))
    const [experienceTo, setexperienceTo] = useState(Dayjs('2024-01-01'))
    const [birthDay, setbirthDay] = useState(Dayjs('2024-01-01'))
    const [formData, setFormData] = useState({
        gender: 'Male',
        dateOfBirth: '',
        address: {
            street: '',
            houseNumber: '',
            city: '',
            postalCode: '',
            country: ''
        },
        phoneNumber: '',
        specialty: 'General Practice',
        education: [],
        licenseNumber: '',
        documents: [],
        experience: [],
        references: [],
        languages: [],
        availability: ''
    })
    const router = useRouter()
    const shoudBeDisabled = user?.applicant[0].application_form_status
    const [disableProceed, setDisableProceed] = useState(shoudBeDisabled ? false : true)
    const email = user?.applicant[0]?.email_id

    useEffect(() => {
        if (user?.applicant[0].application_form_status === 1) {
            router.push(`/interview/${uniqueId}`)
        } else {
            setShowModal(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const fetchUser = async () => {
            const resp = await getUserData(uniqueId)
            setUser(resp)
        }
    }, [setUser, uniqueId, user])

    const onNext = () => {
        if (!disableProceed) {
            router.push(`/interview/${uniqueId}`)
        } else {
            toast.error("Please fill the application form")
        }
    }

    const renderStepStart = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>Welcome! Let's get started with your sign up form.</p>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                    <button
                        onClick={() => { setStep(2); setstep1Disable(true); }}
                        hidden={step1Disable}
                        style={startanswerbuttonStyle}>🚀 Let's go!
                    </button>
                    {step1Disable ?
                        <p style={answerbubbleStyle}>
                            Let's go!
                        </p>
                        : null
                    }
                </div>
            </>
        )
    }

    const addGender = () => {
        const gender = document.getElementById('selected_gender');
        if (!gender.value) {
            toast.error("Please select a gender")
        } else {
            setFormData({
                ...formData,
                gender: gender.value
            });
            setStep(3);
            setstep2Disable(true);
        }
    }
    const renderStepGender = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>What is your Gender?</p>
                        <div hidden={step2Disable}>
                            <Autocomplete
                                id="selected_gender"
                                disablePortal
                                options={genders}
                                sx={{ width: 300, background: 'white', marginBottom: 2 }}
                                renderInput={(params) => <TextField {...params} label="Gender" />}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                    <button
                        onClick={() => { addGender(); }}
                        hidden={step2Disable}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                    {step2Disable ?
                        <p style={answerbubbleStyle}>
                            {formData.gender}
                        </p>
                        : null
                    }
                </div>
            </>
        )
    }

    const addBirthday = () => {
        if (!birthDay) {
            toast.error("Please fill all fields")
        } else {
            setFormData({
                ...formData,
                dateOfBirth: birthDay.format('YYYY-MM-DD')
            });
            setStep(4);
            setstep3Disable(true);
        }
    }
    const renderStepBirth = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>What is your Date of Birth?</p>
                        <div hidden={step3Disable} style={{ marginBottom: 20 }}>
                            <DatePicker
                                value={birthDay}
                                renderInput={(params) => <TextField {...params} id="birthday" />}
                                onChange={(newValue) => { setbirthDay(newValue) }}
                                format="YYYY-MM-DD"
                                slotProps={{
                                    textField: {
                                        inputProps: {
                                            placeholder: "YYYY-MM-DD"
                                        }
                                    }
                                }}
                                closeOnSelect={false}
                                sx={{ background: 'white' }}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                    <button
                        onClick={() => { setStep(2); setstep2Disable(false); }}
                        hidden={step3Disable}
                        style={answerbuttonStyle}>
                        Back
                    </button>
                    <button
                        onClick={() => { addBirthday() }}
                        hidden={step3Disable}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                    {step3Disable ?
                        <p style={answerbubbleStyle}>
                            {formData.dateOfBirth}
                        </p>
                        : null
                    }
                </div>
            </>
        )
    }

    const addAddress = () => {
        const street_name = document.getElementById('street_name');
        const house_number = document.getElementById('house_number');
        const city_name = document.getElementById('city_name');
        const postal_code = document.getElementById('postal_code');
        const country = document.getElementById('country');
        if (!street_name.value || !house_number.value || !city_name.value || !postal_code.value || !country.value) {
            toast.error("Please fill all the fields")
        } else {
            setFormData({
                ...formData,
                address: {
                    ...formData.address,
                    street: street_name.value,
                    houseNumber: house_number.value,
                    city: city_name.value,
                    postalCode: postal_code.value,
                    country: country.value
                }
            });
            setStep(5);
            setstep4Disable(true);
        }
    }
    const renderStepAddress = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>What is your Address?</p>
                        <div hidden={step4Disable} style={{ marginBottom: 20 }}>
                            <FormControl>
                                <p style={{ marginBottom: 0 }}>Street Name</p>
                                <TextField id="street_name" variant="outlined" sx={{ width: 300, background: 'white' }}
                                    value={formData.address.street ? formData.address.street : undefined} />
                                <p style={{ marginBottom: 0 }}>House / Apartment Number</p>
                                <TextField id="house_number" variant="outlined" sx={{ width: 300, background: 'white' }}
                                    value={formData.address.houseNumber ? formData.address.houseNumber : undefined} />
                                <p style={{ marginBottom: 0 }}>City Name</p>
                                <TextField id="city_name" variant="outlined" sx={{ width: 300, background: 'white' }}
                                    value={formData.address.city ? formData.address.city : undefined} />
                                <p style={{ marginBottom: 0 }}>Postal Code</p>
                                <TextField id="postal_code" variant="outlined" sx={{ width: 300, background: 'white' }}
                                    value={formData.address.postalCode ? formData.address.postalCode : undefined} />
                                <p style={{ marginBottom: 0 }}>Country</p>
                                <TextField id="country" variant="outlined" sx={{ width: 300, background: 'white' }}
                                    value={formData.address.country ? formData.address.country : undefined} />
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                    <button
                        onClick={() => { setStep(3); setstep3Disable(false); }}
                        hidden={step4Disable}
                        style={answerbuttonStyle}>
                        Back
                    </button>
                    <button
                        onClick={() => { addAddress() }}
                        hidden={step4Disable}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                    {step4Disable ?
                        <p style={answerbubbleStyle}>
                            Street Name: {formData.address.street}
                            <br />
                            House / Apartment Number: {formData.address.houseNumber}
                            <br />
                            City Name: {formData.address.city}
                            <br />
                            Postal Code: {formData.address.postalCode}
                            <br />
                            Country: {formData.address.country}
                        </p>
                        : null
                    }
                </div>
            </>
        )
    }

    const addPhone = () => {
        const phone_number = document.getElementById('phone_number');
        if (!phone_number.value) {
            toast.error("Please fill all the fields")
        } else {
            setFormData({
                ...formData,
                phoneNumber: phone_number.value
            });
            setStep(6);
            setstep5Disable(true);
        }
    }
    const renderStepPhone = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>What is your Phone Number?</p>
                        <div hidden={step5Disable} style={{ marginBottom: 10 }}>
                            <MuiPhoneNumber id="phone_number" defaultCountry={'us'} onChange={() => { console.log() }} sx={{
                                paddingLeft: 2,
                                paddingRight: 2,
                                paddingTop: 1,
                                paddingBottom: 1,
                                borderRadius: '3px',
                                background: 'white'
                            }} />
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                    <button
                        onClick={() => { setStep(4); setstep4Disable(false); }}
                        hidden={step5Disable}
                        style={answerbuttonStyle}>
                        Back
                    </button>
                    <button
                        onClick={() => { addPhone() }}
                        hidden={step5Disable}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                    {step5Disable ?
                        <p style={answerbubbleStyle}>
                            {formData.phoneNumber}
                        </p>
                        : null
                    }
                </div>
            </>
        )
    }

    const addSpecialty = () => {
        const specialty = document.getElementById('selected_specialty');
        if (!specialty.value) {
            toast.error("Please fill all fields")
        } else {
            setFormData({
                ...formData,
                specialty: specialty.value
            });
            setStep(7);
            setstep6Disable(true);
        }
    }
    const renderStepSpecialty = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>What is your Professional Specialty?</p>
                        <div hidden={step6Disable} style={{ marginBottom: 20 }}>
                            <Stack spacing={2} sx={{ width: 300 }}>
                                <Autocomplete
                                    id="selected_specialty"
                                    disablePortal
                                    options={specialties}
                                    sx={{ width: 300, background: 'white' }}
                                    renderInput={(params) => <TextField {...params} label="Specialty" />}
                                />
                            </Stack>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                    <button
                        onClick={() => { setStep(5); setstep5Disable(false); }}
                        hidden={step6Disable}
                        style={answerbuttonStyle}>
                        Back
                    </button>
                    <button
                        onClick={() => { addSpecialty() }}
                        hidden={step6Disable}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                    {step6Disable ?
                        <p style={answerbubbleStyle}>
                            {formData.specialty}
                        </p>
                        : null
                    }
                </div>
            </>
        )
    }

    const validateEducation = () => {
        if (formData.education && formData.education.length) {
            setopenEduDialog(true)
            //setstep7Disable(true);
            //setStep(8);
        } else {
            toast.error("Please add at least one education")
        }
    }
    const addEducation = () => {
        const school = document.getElementById('school');
        const area_of_study = document.getElementById('area_of_study');
        const degree = document.getElementById('degree');
        if (!school.value || !area_of_study.value || !degree.value || !educationFrom || !educationTo) {
            toast.error("Please fill all the fields")
        } else {
            const newEducationEntry = {
                school: school.value,
                area_of_study: area_of_study.value,
                degree: degree.value,
                education_from: educationFrom?.format('YYYY-MM-DD'),
                education_to: educationTo?.format('YYYY-MM-DD'),
            };
            setFormData(prevState => ({
                ...prevState,
                education: [...prevState.education, newEducationEntry]
            }));
            toast.info("Added")
        }
    }
    const renderStepEducation = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>Add your Educational Attainment
                            <br />
                            <i style={{ color: "gray", fontSize: "14px" }}>(You can add multiple entries)</i>
                        </p>
                        <div hidden={step7Disable} style={{ marginBottom: 20 }}>
                            <div style={{ paddingTop: 10 }}>
                                <p style={{ marginBottom: 0 }}>From</p>
                                <DatePicker
                                    value={educationFrom}
                                    renderInput={(params) => <TextField {...params} id="education_from" />}
                                    onChange={(newValue) => { seteducationFrom(newValue) }}
                                    format="YYYY-MM-DD"
                                    slotProps={{
                                        textField: {
                                            inputProps: {
                                                placeholder: "YYYY-MM-DD"
                                            }
                                        }
                                    }}
                                    sx={{ width: 300, background: 'white' }}
                                    closeOnSelect={false}
                                />
                                <p style={{ marginBottom: 0 }}>Till</p>
                                <DatePicker
                                    value={educationTo}
                                    renderInput={(params) => <TextField {...params} id="education_to" />}
                                    onChange={(newValue) => { seteducationTo(newValue) }}
                                    format="YYYY-MM-DD"
                                    slotProps={{
                                        textField: {
                                            inputProps: {
                                                placeholder: "YYYY-MM-DD"
                                            }
                                        }
                                    }}
                                    sx={{ width: 300, background: 'white' }}
                                    closeOnSelect={false}
                                />
                                <p style={{ marginBottom: 0 }}>School</p>
                                <TextField id="school" variant="outlined" sx={{ width: 300, background: 'white' }} />
                                <p style={{ marginBottom: 0 }}>Area of Study</p>
                                <TextField id="area_of_study" variant="outlined" sx={{ width: 300, background: 'white' }} />
                                <p style={{ marginBottom: 0 }}>Degree</p>
                                <TextField id="degree" variant="outlined" sx={{ width: 300, background: 'white' }} />
                            </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <button
                                onClick={() => addEducation()}
                                hidden={step7Disable}
                                style={answerbuttonStyle}>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                {formData.education ?
                    formData.education.map((edu, index) => (
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }} key={index}>
                            <div>
                                <p style={answerbubbleStyle}>
                                    School: {edu.school}
                                    <br />
                                    Area of Study: {edu.area_of_study}
                                    <br />
                                    Degree: {edu.degree}
                                    <br />
                                    Education From: {edu.education_from}
                                    <br />
                                    Education To: {edu.education_to}
                                </p>
                            </div>
                        </div>
                    ))
                    : null
                }
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                    <button
                        onClick={() => { setStep(6); setstep6Disable(false); setFormData({...formData, education: []});  }}
                        hidden={step7Disable}
                        style={answerbuttonStyle}>
                        Back
                    </button>
                    <button
                        onClick={() => { validateEducation() }}
                        hidden={step7Disable}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                </div>
            </>
        )
    }

    const addLicense = () => {
        const license_number = document.getElementById('license_number');
        if (!license_number.value) {
            toast.error("Please fill all the fields")
        } else {
            setFormData({
                ...formData,
                licenseNumber: license_number.value
            });
            setStep(9);
            setstep8Disable(true);
        }
    }
    const renderStepLicense = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>What is your Medical License Registration Number?</p>
                        <div hidden={step8Disable}>
                            <FormControl>
                                <TextField id="license_number" label="License Number" variant="outlined" style={{ marginBottom: 12, width: 300, background: 'white' }} />
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                    <button
                        onClick={() => { setStep(7); setstep7Disable(false); }}
                        hidden={step8Disable}
                        style={answerbuttonStyle}>
                        Back
                    </button>
                    <button
                        onClick={() => { addLicense() }}
                        hidden={step8Disable}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                    {step8Disable ?
                        <p style={answerbubbleStyle}>
                            {formData.licenseNumber}
                        </p>
                        : null
                    }
                </div>
            </>
        )
    }

    const uploadFiletoERP = async (thefile) => {
        const ERP_API_URL = "https://erp.doktorsjouren.se/"
        const config = {
            method: "POST",
            url: `${ERP_API_URL}api/method/upload_file`,
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json"
            },
            data: {
                file: thefile,
                doctype: "Applicant",
                docname: "cid@youpal.se",
                is_private: 0
            }
        };
        try {
            const response = await axiosInstance(config);
            if (response.data.message) {
                const newDocumentEntry = {
                    filename: response.data.message.file_name,
                    fileurl: response.data.message.file_url
                };
                setFormData(prevState => ({
                    ...prevState,
                    documents: [...prevState.documents, newDocumentEntry]
                }));
            }
        } catch (error) {
            toast.error('Error uploading file');
        }
    }
    const addDocument = (event) => {
        if (event.target.files && event.target.files.length) {
            for (const [key, value] of Object.entries(event.target.files)) {
                uploadFiletoERP(value)
            }
        }
    }
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    const validateDocuments = () => {
        if (formData.documents.length < 2) {
            toast.error("Please add at least two documents")
        } else {
            setopenDocDialog(true)
        }
    }
    const renderStepDocument = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>
                            Please upload a copy of the following documentation to verify your qualifications and credentials:
                            <br />
                            <br />
                            <br />
                            - Medical Degree/Diploma
                            <br />
                            - Valid Medical License
                            <br />
                            - Documentation of your Board Certification (if applicable).
                            <br /><br />
                            <i style={{ color: "gray", fontSize: "14px" }}>(You can add multiple files)</i>
                        </p>
                        <div hidden={step9Disable} style={{ marginBottom: 15 }}>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                style={{ marginTop: 0 }}
                            >
                                <p style={{ padding: 0, margin: 0 }}>Upload Files</p>
                                <VisuallyHiddenInput
                                    type="file"
                                    onChange={(event) => addDocument(event)}
                                    multiple
                                />
                            </Button>
                        </div>
                    </div>
                </div>
                {formData.documents ?
                    formData.documents.map((docu, index) => (
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }} key={index}>
                            <div>
                                <p style={answerbubbleStyle}>
                                    Document: <a href={"https://erp.doktorsjouren.se" + docu.fileurl} style={{ color: "white" }}>{docu.filename}</a>
                                </p>
                            </div>
                        </div>
                    ))
                    : null
                }
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                    <button
                        onClick={() => { setStep(8); setstep8Disable(false); setFormData({...formData, documents: []}); }}
                        hidden={step9Disable}
                        style={answerbuttonStyle}>
                        Back
                    </button>
                    <button
                        onClick={() => { validateDocuments() }}
                        hidden={step9Disable}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                </div>
            </>
        )
    }

    const addExperience = () => {
        const company = document.getElementById('company');
        const position = document.getElementById('position');
        if (!company.value || !position.value || !experienceFrom || !experienceTo) {
            toast.error("Please fill all fields")
        } else {
            const newExperienceEntry = {
                company: company.value,
                position: position.value,
                experience_from: experienceFrom.format('YYYY-MM-DD'),
                experience_to: experienceTo.format('YYYY-MM-DD'),
            };
            setFormData(prevState => ({
                ...prevState,
                experience: [...prevState.experience, newExperienceEntry]
            }));
            toast.info("Added")
        }
    }
    const validateExperience = () => {
        if (formData.experience && formData.experience.length) {
            setopenExpDialog(true);
        } else {
            toast.error("Please add at least one experience")
        }
    }
    const renderStepExperience = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>Add your Work Experience
                            <br />
                            <i style={{ color: "gray", fontSize: "14px" }}>(You can add multiple entries)</i>
                        </p>
                        <div hidden={step10Disable} style={{ paddingTop: 10 }}>
                            <p style={{ marginBottom: 0 }}>From</p>
                            <DatePicker
                                value={experienceFrom}
                                renderInput={(params) => <TextField {...params} id="experience_from" />}
                                onChange={(newValue) => { setexperienceFrom(newValue) }}
                                format="YYYY-MM-DD"
                                slotProps={{
                                    textField: {
                                        inputProps: {
                                            placeholder: "YYYY-MM-DD"
                                        }
                                    }
                                }}
                                closeOnSelect={false}
                                sx={{ width: 300, background: 'white' }}
                            />
                            <p style={{ marginBottom: 0 }}>To</p>
                            <DatePicker
                                value={experienceTo}
                                renderInput={(params) => <TextField {...params} id="experience_to" />}
                                onChange={(newValue) => { setexperienceTo(newValue) }}
                                format="YYYY-MM-DD"
                                slotProps={{
                                    textField: {
                                        inputProps: {
                                            placeholder: "YYYY-MM-DD"
                                        }
                                    }
                                }}
                                closeOnSelect={false}
                                sx={{ width: 300, background: 'white' }}
                            />
                            <p style={{ marginBottom: 0 }}>Company</p>
                            <TextField id="company" variant="outlined" style={{ width: 300, background: 'white' }} />
                            <p style={{ marginBottom: 0 }}>Position</p>
                            <TextField id="position" variant="outlined" style={{ width: 300, background: 'white' }} />
                        </div>
                        <div style={{ textAlign: 'right', paddingTop: 15 }}>
                            <button
                                onClick={() => addExperience()}
                                hidden={step10Disable}
                                style={answerbuttonStyle}>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                {formData.experience ?
                    formData.experience.map((exp, index) => (
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }} key={index}>
                            <div>
                                <p style={answerbubbleStyle}>
                                    Company: {exp.company}
                                    <br />
                                    Position: {exp.position}
                                    <br />
                                    Experience From: {exp.experience_from}
                                    <br />
                                    Experience To: {exp.experience_to}
                                </p>
                            </div>
                        </div>
                    ))
                    : null
                }
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                    <button
                        onClick={() => { setStep(9); setstep9Disable(false); setFormData({...formData, experience: []}); }}
                        hidden={step10Disable}
                        style={answerbuttonStyle}>
                        Back
                    </button>
                    <button
                        onClick={() => { validateExperience() }}
                        hidden={step10Disable}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                </div>
            </>
        )
    }

    const addReference = () => {
        const first_name = document.getElementById('reference_first_name');
        const last_name = document.getElementById('reference_last_name');
        const email = document.getElementById('reference_email');
        if (!first_name.value || !last_name.value || !email.value) {
            toast.error("Please fill all fields")
        } else {
            const newReferenceEntry = {
                first_name: first_name.value,
                last_name: last_name.value,
                email: email.value,
            };
            setFormData(prevState => ({
                ...prevState,
                references: [...prevState.references, newReferenceEntry]
            }));
            toast.info("Added")
        }
    }
    const validateReference = () => {
        if (formData.references && formData.references.length) {
            if (formData.references.length < 2) {
                toast.error("Please add at least two reference")
            } else {
                setopenRefDialog(true)
            }
        } else {
            toast.error("Please add at least two reference")
        }
    }
    const renderStepReference = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>Add your Reference</p>
                        <div hidden={step11Disable}>
                            <FormControl>
                                <p style={{ marginBottom: 0 }}>First Name</p>
                                <TextField id="reference_first_name" variant="outlined" style={{ width: 300, background: 'white' }} />
                                <p style={{ marginBottom: 0 }}>Last Name</p>
                                <TextField id="reference_last_name" variant="outlined" style={{ width: 300, background: 'white' }} />
                                <p style={{ marginBottom: 0 }}>Email</p>
                                <TextField id="reference_email" variant="outlined" style={{ width: 300, background: 'white' }} />
                            </FormControl>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
                            <button
                                onClick={() => addReference()}
                                hidden={step11Disable}
                                style={answerbuttonStyle}>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                {formData.references ?
                    formData.references.map((refe, index) => (
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }} key={index}>
                            <div>
                                <p style={answerbubbleStyle}>
                                    First Name: {refe.first_name}
                                    <br />
                                    Last Name: {refe.last_name}
                                    <br />
                                    Email: {refe.email}
                                </p>
                            </div>
                        </div>
                    ))
                    : null
                }
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                    <button
                        onClick={() => { setStep(10); setstep10Disable(false); setFormData({...formData, references: []}); }}
                        hidden={step11Disable}
                        style={answerbuttonStyle}>
                        Back
                    </button>
                    <button
                        onClick={() => { validateReference() }}
                        hidden={step11Disable}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                </div>
            </>
        )
    }

    const addLanguage = () => {
        const language = document.getElementById('selected_language');
        if (!language.value) {
            toast.error("Please select language")
        } else {
            const newLanguageEntry = {
                value: language.value
            };
            setFormData(prevState => ({
                ...prevState,
                languages: [...prevState.languages, newLanguageEntry]
            }));
            toast.info("Added")
        }
    }
    const validateLanguage = () => {
        if (formData.languages && formData.languages.length) {
            setopenLangDialog(true)
        } else {
            toast.error("Please add at least one language")
        }
    }
    const renderStepLanguage = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>What are the languages you can use to communicate effectively with patients and deliver medical care?</p>
                        <div hidden={step12Disable} style={{ marginTop: 20 }}>
                            <Autocomplete
                                id="selected_language"
                                disablePortal
                                options={languages}
                                sx={{ width: 300, background: 'white' }}
                                renderInput={(params) => <TextField {...params} label="Language" />}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <button
                                onClick={() => addLanguage()}
                                hidden={step12Disable}
                                style={answerbuttonStyle}>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                {formData.languages ?
                    formData.languages.map((lang, index) => (
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }} key={index}>
                            <div>
                                <p style={answerbubbleStyle}>
                                    Language: {lang.value}
                                </p>
                            </div>
                        </div>
                    ))
                    : null
                }
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                    <button
                        onClick={() => { setStep(11); setstep11Disable(false); setFormData({...formData, languages: []}); }}
                        hidden={step12Disable}
                        style={answerbuttonStyle}>
                        Back
                    </button>
                    <button
                        onClick={() => { validateLanguage() }}
                        hidden={step12Disable}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                </div>
            </>
        )
    }

    const addAvailability = () => {
        const selected_availability = document.getElementById('selected_availability');
        if (!selected_availability.value) {
            toast.error("Please select availability")
        } else {
            formData.availability = selected_availability.value
            updateDataToERP()
        }
    }
    const renderStepAvailability = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>What is your availability?</p>
                        <div hidden={step13Disable}>
                            <Autocomplete
                                disablePortal
                                id="selected_availability"
                                options={availabilities}
                                sx={{ width: 300, background: 'white', marginBottom: 2 }}
                                renderInput={(params) => <TextField {...params} label="Availability" />}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                    <button
                        onClick={() => { setStep(12); setstep12Disable(false); }}
                        hidden={step13Disable}
                        style={answerbuttonStyle}>
                        Back
                    </button>
                    <button
                        onClick={() => { addAvailability() }}
                        hidden={step13Disable}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                    {step13Disable ?
                        <p style={answerbubbleStyle}>
                            Availability: {formData.availability}
                        </p>
                        : null
                    }
                </div>
            </>
        )
    }

    const renderStepLast = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle} >
                        <p>
                            Thank you for your submission, you would be able to move to next step of application in few seconds.
                            <br /><br /><br />
                            <i>Please go to next step by clicking the button in bottom right corner</i>
                        </p>
                    </div>
                </div>
            </>
        )
    }

    const updateDataToERP = async () => {
        const ERP_API_URL = "https://erp.doktorsjouren.se/"
        let languages_lst = []
        formData.languages.forEach(element => {
            languages_lst.push(element.value)
        });
        let languages_str = languages_lst.join(", ")
        let final_education = []
        formData.education.forEach(element => {
            final_education.push({
                school: element.school,
                area_of_study: element.area_of_study,
                degree: element.degree,
                from_date: element.education_from,
                till_date: element.education_to,
            })
        });
        let final_experience = []
        formData.experience.forEach(element => {
            final_experience.push({
                company: element.company,
                position: element.position,
                from_date: element.experience_from,
                till_date: element.experience_to,
            })
        });
        const config = {
            method: "PUT",
            url: `${ERP_API_URL}/api/resource/Applicant/${email}`,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Token 50894a2a32f7939:c7d6cec526ce3a0"
            },
            data: {
                gender: formData.gender,
                birthday: formData.dateOfBirth,
                address: formData.address.street + ", " + formData.address.houseNumber + ", " + formData.address.city + ", " + formData.address.postalCode + ", " + formData.address.country,
                country: formData.address.country,
                phone_number: formData.phoneNumber,
                specialty: formData.specialty,
                registration_number: formData.licenseNumber,
                application_form_status: 1,
                current_status: "Video Interview",
                languages: languages_str,
                availability: formData.availability,
                experience: final_experience,
                education: final_education,
                reference_table: formData.references,
            }
        };
        try {
            const response = await axiosInstance(config);
            if (response.data) {
                setStep(14);
                setstep13Disable(true);
                setDisableProceed(false);
            }
        } catch (error) {
            toast.error('Error updating data');
        }
    }

    const stepComponents = {
        1: [renderStepStart],
        2: [renderStepStart, renderStepGender],
        3: [renderStepStart, renderStepGender, renderStepBirth],
        4: [renderStepStart, renderStepGender, renderStepBirth, renderStepAddress],
        5: [renderStepStart, renderStepGender, renderStepBirth, renderStepAddress, renderStepPhone],
        6: [renderStepStart, renderStepGender, renderStepBirth, renderStepAddress, renderStepPhone, renderStepSpecialty],
        7: [renderStepStart, renderStepGender, renderStepBirth, renderStepAddress, renderStepPhone, renderStepSpecialty, renderStepEducation],
        8: [renderStepStart, renderStepGender, renderStepBirth, renderStepAddress, renderStepPhone, renderStepSpecialty, renderStepEducation, renderStepLicense],
        9: [renderStepStart, renderStepGender, renderStepBirth, renderStepAddress, renderStepPhone, renderStepSpecialty, renderStepEducation, renderStepLicense, renderStepDocument],
        10: [renderStepStart, renderStepGender, renderStepBirth, renderStepAddress, renderStepPhone, renderStepSpecialty, renderStepEducation, renderStepLicense, renderStepDocument, renderStepExperience],
        11: [renderStepStart, renderStepGender, renderStepBirth, renderStepAddress, renderStepPhone, renderStepSpecialty, renderStepEducation, renderStepLicense, renderStepDocument, renderStepExperience, renderStepReference],
        12: [renderStepStart, renderStepGender, renderStepBirth, renderStepAddress, renderStepPhone, renderStepSpecialty, renderStepEducation, renderStepLicense, renderStepDocument, renderStepExperience, renderStepReference, renderStepLanguage],
        13: [renderStepStart, renderStepGender, renderStepBirth, renderStepAddress, renderStepPhone, renderStepSpecialty, renderStepEducation, renderStepLicense, renderStepDocument, renderStepExperience, renderStepReference, renderStepLanguage, renderStepAvailability],
        14: [renderStepStart, renderStepGender, renderStepBirth, renderStepAddress, renderStepPhone, renderStepSpecialty, renderStepEducation, renderStepLicense, renderStepDocument, renderStepExperience, renderStepReference, renderStepLanguage, renderStepAvailability, renderStepLast]
    };

    const renderStep = () => {
        return step >= 1 ? (
            <>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    {stepComponents[step]?.map((Component, index) => (
                        <Component key={index} />
                    ))}
                </LocalizationProvider>
            </>
        ) : null;
    }

    return (
        <MainLayout>
            <div>
                <div>
                    <img src="/assets/youmedico_logo.svg" alt="" style={{ height: 35, marginTop: 30, marginLeft: 30, marginBottom: 15 }} />
                    <div style={{ background: "#F1F2F9", minHeight: 600, padding: 50, maxHeight: 600, overflow: "auto" }}>
                        <div style={{ display: "block" }}>
                            {renderStep()}
                        </div>
                    </div>
                </div>
                <Grid
                    sx={{ pt: 3, flexDirection: { xs: "row-reverse", md: "row" } }}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <div />
                    <Button disabled={disableProceed} onClick={onNext} variant="contained">
                        Go to next step
                    </Button>
                </Grid>
            </div>
            <Modal open={openEduDialog}>
                <h4 style={{ paddingLeft: 30, paddingRight: 30 }} >Are you sure you dont want to add more Educational Attainment?</h4>
                <div style={{ paddingBottom: 20, paddingRight: 20, display: "flex", justifyContent: "end", gap: "10px" }}>
                    <Button variant="contained" onClick={() => setopenEduDialog(false)}>Add More</Button>
                    <Button variant="outlined" onClick={() => { setopenEduDialog(false); setstep7Disable(true); setStep(8); }}>Yes</Button>
                </div>
            </Modal>
            <Modal open={openDocDialog}>
                <h4 style={{ paddingLeft: 30, paddingRight: 30 }} >Are you sure you dont want to add more Documents?</h4>
                <div style={{ paddingBottom: 20, paddingRight: 20, display: "flex", justifyContent: "end", gap: "10px" }}>
                    <Button variant="contained" onClick={() => setopenDocDialog(false)}>Add More</Button>
                    <Button variant="outlined" onClick={() => { setopenDocDialog(false); setStep(10); setstep9Disable(true); }}>Yes</Button>
                </div>
            </Modal>
            <Modal open={openExpDialog}>
                <h4 style={{ paddingLeft: 30, paddingRight: 30 }} >Are you sure you dont want to add more Work Experience?</h4>
                <div style={{ paddingBottom: 20, paddingRight: 20, display: "flex", justifyContent: "end", gap: "10px" }}>
                    <Button variant="contained" onClick={() => setopenExpDialog(false)}>Add More</Button>
                    <Button variant="outlined" onClick={() => { setopenExpDialog(false); setStep(11); setstep10Disable(true); }}>Yes</Button>
                </div>
            </Modal>
            <Modal open={openRefDialog}>
                <h4 style={{ paddingLeft: 30, paddingRight: 30 }} >Are you sure you dont want to add more Reference?</h4>
                <div style={{ paddingBottom: 20, paddingRight: 20, display: "flex", justifyContent: "end", gap: "10px" }}>
                    <Button variant="contained" onClick={() => setopenRefDialog(false)}>Add More</Button>
                    <Button variant="outlined" onClick={() => { setopenRefDialog(false); setStep(12); setstep11Disable(true); }}>Yes</Button>
                </div>
            </Modal>
            <Modal open={openLangDialog}>
                <h4 style={{ paddingLeft: 30, paddingRight: 30 }} >Are you sure you dont want to add more Language?</h4>
                <div style={{ paddingBottom: 20, paddingRight: 20, display: "flex", justifyContent: "end", gap: "10px" }}>
                    <Button variant="contained" onClick={() => setopenLangDialog(false)}>Add More</Button>
                    <Button variant="outlined" onClick={() => { setopenLangDialog(false); setStep(13); setstep12Disable(true); }}>Yes</Button>
                </div>
            </Modal>
            <Modal open={showModal} setOpen={() => console.log("")}>
                <div className="modal-content">
                    <Typography fontWeight={500} paddingBottom={1} color="primary" variant="h5">
                        Welcome to Verification process:
                    </Typography>
                    <Typography>
                        Thank you for your interest in joining our telemedicine platform. To start offering
                        consultations, please complete our straightforward verification process:
                    </Typography>
                    <List sx={{ fontWeight: "light", padding: 0, pl: 2, listStyleType: "disc" }}>
                        <ListItem
                            sx={{
                                display: "list-item",
                                padding: 0,
                                paddingTop: 0.1,

                                lineHeight: 1.5,
                                fontSize: 16,
                            }}
                        >
                            <Typography fontWeight={500} paddingY={1}>
                                Application Form:
                            </Typography>
                            <Typography>
                                Fill out the application form with your professional data to help us learn more about
                                your background and qualifications.
                            </Typography>
                        </ListItem>
                        <ListItem
                            sx={{
                                display: "list-item",
                                padding: 0,
                                paddingTop: 0.1,
                                lineHeight: 1.5,
                                fontSize: 16,
                            }}
                        >
                            <Typography fontWeight={500} paddingY={1}>
                                Video Interview:
                            </Typography>{" "}
                            <Typography>Record answers about your professional experience.</Typography>
                        </ListItem>
                        <ListItem
                            sx={{
                                display: "list-item",
                                padding: 0,
                                paddingTop: 0.1,

                                lineHeight: 1.5,
                                fontSize: 16,
                            }}
                        >
                            <Typography fontWeight={500} paddingY={1}>
                                Background Check:
                            </Typography>
                            <Typography>
                                Submit your ID to verify your identity and enhance platform security.
                            </Typography>
                        </ListItem>
                        <ListItem
                            sx={{
                                display: "list-item",
                                padding: 0,
                                paddingTop: 0.1,

                                lineHeight: 1.5,
                                fontSize: 16,
                            }}
                        >
                            <Typography fontWeight={500} paddingY={1}>
                                License Review:
                            </Typography>
                            <Typography>
                                Our team will review your application and verify your medical license with relevant
                                regulatory bodies to ensure it's current and valid.
                            </Typography>
                        </ListItem>
                    </List>
                    <Typography paddingY={2} color="primary">
                        After your documentation is verified, you will be asked to sign a legal agreement and add your
                        billing information to receive timely payments for your services. Once done, you will receive a
                        confirmation email and gain access our platform
                    </Typography>
                    <Box textAlign="center">
                        <Button variant="contained" onClick={() => setShowModal(false)}>
                            Let's Start
                        </Button>
                    </Box>
                </div>
            </Modal>
        </MainLayout >
    )
}
