const STORAGE_KEYS = {
    AUDIO_INPUT: "audio_input",
    AUDIO_OUTPUT: "audio_output",
    VIDEO_INPUT: "video_input",
}

function getItem(key) {
    return localStorage.getItem(key)
}

function getItemAsync(key, valueToReplace) {
    if (typeof valueToReplace !== "string") {
        throw new Error("Value to replacement must be a string!")
    }

    return new Promise(resolve => {
        setTimeout(() => {
            resolve(getItem(key) || valueToReplace)
        }, 0)
    })
}

function setItem(key, _value) {
    const value = typeof _value !== "string" ? JSON.stringify(_value) : _value

    localStorage.setItem(key, value)
    return _value
}

function setItemAsync(key, value) {
    return Promise.resolve(setItem(key, value))
}

function removeItem(key) {
    const value = getItem(key)
    localStorage.removeItem(key)
    return value
}

function clearStorage() {
    localStorage.clear()
    sessionStorage.clear()
}

export { STORAGE_KEYS, getItem, getItemAsync, setItem, setItemAsync, removeItem, clearStorage }
