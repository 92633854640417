import { Box, Button, Grid } from "@mui/material"
import { useUser } from "hooks/useUser"
import { useRouter } from "hooks/useRouter"
import { toast } from "react-toastify"
import MainLayout from "components/layout/MainLayout"
import { useEffect, useState } from "react"
import { STATUS } from "utils/constants"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axiosInstance from "utils/axiosInstance"


const questionbubbleStyle = {
    marginLeft: 25,
    background: "rgb(245, 245, 245)",
    paddingTop: 16,
    paddingLeft: 25,
    paddingBottom: 16,
    paddingRight: 25,
    fontWeight: 410,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    borderColor: '#e5e5e5',
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderRadius: "10px"
};

const answerbubbleStyle = {
    background: "rgba(74, 144, 226, 1)",
    paddingTop: 20,
    paddingLeft: 30,
    paddingBottom: 20,
    paddingRight: 30,
    color: "white",
    borderColor: '#e5e5e5',
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderRadius: "10px",
    fontWeight: 410,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "1rem",
};

const startanswerbuttonStyle = {
    background: "rgba(74, 144, 226, 1)",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 40,
    color: "white",
    borderColor: "rgba(255, 255, 255, 0.56)",
    borderRadius: 10,
    fontWeight: 410,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "1rem",
    cursor: "pointer"
};

const answerbuttonStyle = {
    background: "rgba(74, 144, 226, 1)",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 40,
    paddingRight: 40,
    color: "white",
    borderColor: "rgba(255, 255, 255, 0.56)",
    borderRadius: 10,
    fontWeight: 410,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "1rem",
    cursor: "pointer"
};

export const Billing = () => {
    const { user, uniqueId, setUser } = useUser()
    const router = useRouter()
    const [step, setStep] = useState(1)
    const [stepsDisabled, setstepsDisabled] = useState([])
    const [formData, setFormData] = useState({
        payment_method: '',
        wise_id: '',
        account_holder_name: '',
        bank_name: '',
        bank_address: '',
        account_number: '',
        iban: '',
        swift: '',
        bic: '',
        termsAgreed: 0
    })
    const shouldBeDisabled = user?.applicant[0]?.billing_information_status
    const [disableProceed, setDisableProceed] = useState(shouldBeDisabled ? false : true)
    const email = user?.applicant[0]?.email_id
    const applicationStatus = user?.applicant[0]?.application_form_status
    const videoStatus = user?.applicant[0]?.video_interview_status
    const manualCheck = user?.applicant[0]?.manual_check_status
    const paymentOptions = ["Bank", "Wise"]

    useEffect(() => {
        if (user) {
            if (applicationStatus === 0) {
                router.push(`/${uniqueId}`)
            } else if (videoStatus === 0 && applicationStatus === 1) {
                router.push(`/interview/${uniqueId}`)
            } else if (videoStatus === 1 && applicationStatus === 1 && manualCheck !== STATUS.COMPLETED) {
                router.push(`/manual-check/${uniqueId}`)
            } else if (manualCheck === STATUS.COMPLETED && !shouldBeDisabled) {
                router.push(`/billing/${uniqueId}`)
            } else if (shouldBeDisabled) {
                router.push("/congratulations")
            }
        }
    }, [user, disableProceed])

    //    useEffect(() => {
    //        const fetchUser = async () => {
    //            const resp = await getUserData(uniqueId)
    //            setUser(resp)
    //        }
    //        const interval = setInterval(() => fetchUser(), 3000)
    //        return () => {
    //            clearInterval(interval)
    //        }
    //    }, [setUser, uniqueId, user])

    const onFinish = () => {
        if (user?.applicant[0]?.billing_information_status === 1 || !disableProceed) {
            router.push("/congratulations")
        } else {
            toast.error("Please fill the billing form")
        }
    }

    const renderStepStart = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle}>
                        <p>Please fill out information regarding your billing details.</p>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                    <button
                        onClick={() => { stepsDisabled.push("renderStepStart"); setStep(2) }}
                        hidden={stepsDisabled.includes("renderStepStart") ? true : false}
                        style={startanswerbuttonStyle}>
                        🚀 Let's go!
                    </button>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                    {stepsDisabled.includes("renderStepStart") ?
                        <p style={answerbubbleStyle}>
                            Let's go!
                        </p>
                        : null
                    }
                </div>
            </>
        )
    }

    const addPayment = () => {
        const selected_payment = document.getElementById('selected_payment');
        if (!selected_payment.value) {
            toast.error("Please fill all the fields")
        } else {
            setFormData({
                ...formData,
                payment_method: selected_payment.value
            });
            stepsDisabled.push("renderStepPayment");
            setStep(3);
        }
    }
    const renderStepPayment = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle}>
                        <p>How you would like to get paid?</p>
                        <div hidden={stepsDisabled.includes("renderStepPayment") ? true : false} style={{ paddingTop: 10 }}>
                            <Autocomplete
                                id="selected_payment"
                                disablePortal
                                options={paymentOptions}
                                sx={{ width: 300, background: "white", marginBottom: 2 }}
                                renderInput={(params) => <TextField {...params} label="Payment Type" />}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                    <button
                        onClick={() => { addPayment() }}
                        hidden={stepsDisabled.includes("renderStepPayment") ? true : false}
                        style={answerbuttonStyle}>
                        Next
                    </button>
                    {stepsDisabled.includes("renderStepPayment") ?
                        <p style={answerbubbleStyle}>
                            {formData.payment_method}
                        </p>
                        : null
                    }
                </div>
            </>
        )
    }

    const addBankDetails = () => {
        const account_holder_name = document.getElementById('account_holder_name');
        const bank_name = document.getElementById('bank_name');
        const bank_address = document.getElementById('bank_address');
        const account_number = document.getElementById('account_number');
        const clearing_number = document.getElementById('clearing_number');
        const iban = document.getElementById('iban');
        const swift = document.getElementById('swift');
        const bic = document.getElementById('bic');
        if (!account_holder_name.value || !bank_name.value || !bank_address.value || !account_number.value || !clearing_number.value || !iban.value || !swift.value || !bic.value) {
            toast.error("Please fill all the fields")
        } else {
            setFormData({
                ...formData,
                account_holder_name: account_holder_name.value,
                bank_name: bank_name.value,
                bank_address: bank_address.value,
                account_number: account_number.value,
                iban: iban.value,
                swift: swift.value,
                bic: bic.value,
            });
            stepsDisabled.push("renderStepBankdetails");
            setStep(4);
        }
    }
    const addWise = () => {
        const wise_id = document.getElementById('wise_id');
        if (!wise_id.value) {
            toast.error("Please fill all the fields")
        } else {
            setFormData({
                ...formData,
                wise_id: wise_id.value,
            });
            stepsDisabled.push("renderStepBankdetails");
            setStep(4);
        }
    }
    const renderStepBankdetails = () => {
        if (formData.payment_method == "Bank") {
            return (
                <>
                    <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                        <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                        <div style={questionbubbleStyle}>
                            <p>Fill your Banking Details</p>
                            <div hidden={stepsDisabled.includes("renderStepBankdetails") ? true : false} style={{ marginTop: 35, marginBottom: 15 }}>
                                <p style={{ marginBottom: 0 }}>Account Holder Name</p>
                                <TextField id="account_holder_name" variant="outlined" style={{ width: 300, background: "white" }}
                                    value={formData.account_holder_name ? formData.account_holder_name : undefined} />
                                <p style={{ marginBottom: 0 }}>Bank Name</p>
                                <TextField id="bank_name" variant="outlined" style={{ width: 300, background: "white" }}
                                    value={formData.bank_name ? formData.bank_name : undefined} />
                                <p style={{ marginBottom: 0 }}>Bank Address</p>
                                <TextField id="bank_address" variant="outlined" style={{ width: 300, background: "white" }}
                                    value={formData.bank_address ? formData.bank_address : undefined} />
                                <p style={{ marginBottom: 0 }}>Account Number</p>
                                <TextField id="account_number" variant="outlined" style={{ width: 300, background: "white" }}
                                    value={formData.account_number ? formData.account_number : undefined} />
                                <p style={{ marginBottom: 0 }}>Clearing Number</p>
                                <TextField id="clearing_number" variant="outlined" style={{ width: 300, background: "white" }}
                                    value={undefined} />
                                <p style={{ marginBottom: 0 }}>IBAN</p>
                                <TextField id="iban" variant="outlined" style={{ width: 300, background: "white" }}
                                    value={formData.iban ? formData.iban : undefined} />
                                <p style={{ marginBottom: 0 }}>SWIFT</p>
                                <TextField id="swift" variant="outlined" style={{ width: 300, background: "white" }}
                                    value={formData.swift ? formData.swift : undefined} />
                                <p style={{ marginBottom: 0 }}>BIC</p>
                                <TextField id="bic" variant="outlined" style={{ width: 300, background: "white" }}
                                    value={formData.bic ? formData.bic : undefined} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                        <button
                            onClick={() => { setStep(2); setstepsDisabled(stepsDisabled.filter(item => item !== "renderStepPayment")); }}
                            hidden={stepsDisabled.includes("renderStepBankdetails") ? true : false}
                            style={answerbuttonStyle}>
                            Back
                        </button>
                        <button
                            onClick={() => { addBankDetails() }}
                            hidden={stepsDisabled.includes("renderStepBankdetails") ? true : false}
                            style={answerbuttonStyle}>
                            Next
                        </button>
                        {stepsDisabled.includes("renderStepBankdetails") ?
                            <div style={answerbubbleStyle}>
                                <p>
                                    Account Holder Name: {formData.account_holder_name}
                                    <br />
                                    Bank Name: {formData.bank_name}
                                    <br />
                                    Bank Address: {formData.bank_address}
                                    <br />
                                    Account Number: {formData.account_number}
                                    <br />
                                    IBAN: {formData.iban}
                                    <br />
                                    SWIFT: {formData.swift}
                                    <br />
                                    BIC: {formData.bic}
                                </p>
                            </div>
                            : null
                        }
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                        <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                        <div style={questionbubbleStyle}>
                            <p>
                                Wise ID
                                <br /><br />
                                <i>(email connected with your wise account)</i>
                            </p>
                            <div hidden={stepsDisabled.includes("renderStepBankdetails") ? true : false}>
                                <TextField id="wise_id" variant="outlined" style={{ width: 300, background: "white", marginBottom: 10 }}
                                    value={formData.wise_id ? formData.wise_id : undefined} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                        <button
                            onClick={() => { setStep(2); setstepsDisabled(stepsDisabled.filter(item => item !== "renderStepPayment")); }}
                            hidden={stepsDisabled.includes("renderStepBankdetails") ? true : false}
                            style={answerbuttonStyle}>
                            Back
                        </button>
                        <button
                            onClick={() => { addWise() }}
                            hidden={stepsDisabled.includes("renderStepBankdetails") ? true : false}
                            style={answerbuttonStyle}>
                            Next
                        </button>
                        {stepsDisabled.includes("renderStepBankdetails") ?
                            <p style={answerbubbleStyle}>
                                {formData.wise_id}
                            </p>
                            : null
                        }
                    </div>
                </>
            )
        }
    }

    const updateDataToERP = async () => {
        setFormData({
            ...formData,
            termsAgreed: 1
        });
        const ERP_API_URL = "https://erp.doktorsjouren.se/"
        const config = {
            method: "PUT",
            url: `${ERP_API_URL}/api/resource/Applicant/${email}`,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Token 50894a2a32f7939:c7d6cec526ce3a0"
            },
            data: {
                payment_method: formData.payment_method,
                wise_id: formData.wise_id,
                account_holder_name: formData.account_holder_name,
                bank_name: formData.bank_name,
                bank_address: formData.bank_address,
                account_number: formData.account_number,
                iban: formData.iban,
                swift: formData.swift,
                bic: formData.bic,
                billing_information_status: 1,
                current_status: "Completed"
            }
        };
        try {
            const response = await axiosInstance(config);
            if (response.data) {
                stepsDisabled.push("renderStepLast");
                setDisableProceed(false)
            }
        } catch (error) {
            toast.error('Error updating data');
        }
    }
    const renderStepLast = () => {
        return (
            <>
                <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                    <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                    <div style={questionbubbleStyle}>
                        <p style={{ marginBottom: 25 }}>
                            One last step!
                            <br /><br />
                            Please read and agree to <a href="https://www.youmedico.com/privacy-policy">privacy policy</a>.
                        </p>
                        <button
                            onClick={() => { updateDataToERP() }}
                            hidden={stepsDisabled.includes("renderStepLast") ? true : false}
                            style={answerbuttonStyle}>
                            I have read and agreed!
                        </button>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10, gap: "10px" }}>
                    <button
                        onClick={() => { setStep(3); setstepsDisabled(stepsDisabled.filter(item => item !== "renderStepBankdetails")); }}
                        hidden={stepsDisabled.includes("renderStepLast") ? true : false}
                        style={answerbuttonStyle}>
                        Back
                    </button>
                    {stepsDisabled.includes("renderStepLast") ?
                        <p style={{ background: "rgba(74, 144, 226, 1)", padding: 20, color: "white", borderColor: "rgba(255, 255, 255, 0.56)", borderRadius: "10px", fontFamily: "'Inter', sans-serif", fontWeight: 540, fontSize: "1rem" }}>
                            I have read and agreed!
                        </p>
                        : null
                    }
                </div>
                {stepsDisabled.includes("renderStepLast") ?
                    <div style={{ alignItems: "flex-end", display: "flex", justifyItems: "start" }}>
                        <img alt="" className="Media is-original is-unselectable is-contain" src="https://storage.googleapis.com/media.landbot.io/352549/channels/XAOBJ5J5NQLO5YH13AMA21297GVOSNQO.png" style={{ height: 80 }} />
                        <div style={questionbubbleStyle}>
                            <p>
                                Thank you!
                            </p>
                        </div>
                    </div>
                    : null
                }
            </>
        )
    }

    const stepComponents = {
        1: [renderStepStart],
        2: [renderStepStart, renderStepPayment],
        3: [renderStepStart, renderStepPayment, renderStepBankdetails],
        4: [renderStepStart, renderStepPayment, renderStepBankdetails, renderStepLast]
    };

    const renderStep = () => {
        return step >= 1 ? (
            <>
                {stepComponents[step]?.map((Component, index) => (
                    <Component key={index} />
                ))}
            </>
        ) : null;
    }

    return (
        <MainLayout>
            <Box>
                <div>
                    <img src="/assets/youmedico_logo.svg" alt="" style={{ height: 35, marginTop: 30, marginLeft: 30, marginBottom: 15 }} />
                    <div style={{ background: "#F1F2F9", minHeight: 600, padding: 50, maxHeight: 600, overflow: "auto" }}>
                        <div style={{ display: "block" }}>
                            {renderStep()}
                        </div>
                    </div>
                </div>
                <Grid
                    sx={{ pt: 3, flexDirection: { xs: "row-reverse", md: "row" } }}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <div />
                    <Button disabled={disableProceed} onClick={onFinish} variant="contained">
                        Finish
                    </Button>
                </Grid>
            </Box>
        </MainLayout>
    )
}
