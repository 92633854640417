import { useEffect, useState } from "react"
import { Drawer, Box, AppBar, IconButton, Toolbar, useMediaQuery, useTheme } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import Sidebar from "components/Sidebar"
import { Logo } from "components/Logo"

function MainLayout({ children }) {
    const theme = useTheme()
    const isMobileSidebarNeeded = useMediaQuery(theme.breakpoints.down("md"))
    const [open, setOpen] = useState(false)

    const toggleDrawer = () => {
        setOpen(!open)
    }

    const sidebar = <Sidebar />

    useEffect(() => {
        // window.addEventListener('mouseover', initLandbot, { once: true });
        // window.addEventListener('touchstart', initLandbot, { once: true });
        //     ;(function () {
        //         window.ybug_settings = { id: "qs6mxkm127kkx4fkz16r" }
        //         var ybug = document.createElement("script")
        //         ybug.type = "text/javascript"
        //         ybug.async = true
        //         ybug.src = "https://widget.ybug.io/button/" + window.ybug_settings.id + ".js"
        //         var s = document.getElementsByTagName("script")[0]
        //         s.parentNode.insertBefore(ybug, s)
        //     })()
        // var script = document.createElement("script")
        //     script.type = "text/javascript"
        //     script.setAttribute("SameSite", "None; Secure")
        //     script.onload = triggerLandbot()
        //     script.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js"
        //     document.head.appendChild(script)
    }, [])

    // function openLandbot() {
    //     if (scriptUploaded) {
    //         myLandbot.open()
    //     } else {
    //         var script = document.createElement("script")
    //         script.type = "text/javascript"
    //         script.setAttribute("SameSite", "None; Secure")
    //         script.onload = triggerLandbot()
    //         script.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js"
    //         document.head.appendChild(script)
    //     }
    // }

    // function triggerLandbot() {
    //     setTimeout(function () {
    //         var script = document.createElement("script")

    //         //Please change the path to the one on you share section.

    //         script.innerHTML = myLandbot = `new Landbot.Popup({
    //                 configUrl: 'https://chats.landbot.io/v3/H-1786332-ZTH9D6VBY2MEOSED/index.json',});`
    //         document.body.appendChild(script)
    //         scriptUploaded = true
    //         myLandbot.onLoad(function () {
    //             myLandbot.open()
    //         })
    //     }, 3000)
    // }

    return (
        <Box sx={{ display: "flex" }}>
            {isMobileSidebarNeeded ? (
                <Box>
                    <AppBar color="white" open={open}>
                        <Toolbar
                            sx={{
                                pr: "24px", // keep right padding when drawer closed
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: "2px",
                                    ...(open && { display: "none" }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Logo />
                        </Toolbar>
                    </AppBar>
                    <Drawer open={open} onClose={toggleDrawer}>
                        {sidebar}
                    </Drawer>
                </Box>
            ) : (
                sidebar
            )}
            <Box
                component="main"
                sx={{
                    width: "100vw",
                    px: isMobileSidebarNeeded ? 2 : 5,
                    pt: isMobileSidebarNeeded ? 9 : 3,
                    pb: isMobileSidebarNeeded ? 2 : 5,
                }}
            >
                {children}
            </Box>
        </Box>
    )
}

export default MainLayout
